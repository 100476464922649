import { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { Pagination } from '@mui/lab';
import useToken from '../../../components/UseToken';
import 'react-toastify/dist/ReactToastify.css';

export default function PushLogTable() {
  const { token, setToken } = useToken();
  const [pushes, setPushes] = useState();
  const [page, setPage] = useState(1);
  const [pushCount, setPushCount] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const toSet = [];

    axios
      .get(`push/manual?skip=${10 * (page - 1)}&limit=10`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        res.data.result.forEach((push) => {
          if (push.category !== "manual" || new Date(push.time) > new Date()) return;
          toSet.push(<ListItem
            key={push._id}
          >
            <ListItemText primary={push.name} secondary={push.content} primaryTypographyProps={{ width: "10rem" }}  secondaryTypographyProps={{ style: { whiteSpace: "normal", wordWrap: "break-word" }, width: "10rem"}} />
            <ListItemText primary={moment(push.time).tz("Europe/Moscow").format("DD.MM.YYYY")} secondary={moment(push.time).tz("Europe/Moscow").format("HH:mm z")} sx={{ml: "5rem"}}/>
          </ListItem>)
        })

        setPushes(toSet);
        setPushCount(res.data.count);
        window.scrollTo(0,0);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          setToken('');
        }
        toast.error(`Ошибка при загрузке данных! Код ошибки: ${err.response?.status ?? 'неизвестная ошибка'}`);
      });
  }, [token, page]);

  return (
    <Container fullWidth sx={{mt: 5}}>
        <List>
          <Stack divider={<Divider />}>{pushes}</Stack>
        </List>
      <Pagination count={Math.ceil(pushCount / 10)} page={page} onChange={handleChange} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
    </Container>
  );
}
