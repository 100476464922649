import * as Yup from 'yup';
import { Container, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect} from 'react';
import Page from '../components/Page';
import { FormProvider, RHFTextField } from '../components/hook-form';
import useToken from '../components/UseToken';

export default function EditVideo() {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const regex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
  const { token, setToken } = useToken();

  const VideoSchema = Yup.object().shape({
    title: Yup.string().required('Название обязательно'),
    description: Yup.string().required('Описание обязательно'),
    link: Yup.string().matches(regex, 'Это не ссылка на видео YouTube').required('Ссылка обязательна'),
  });

  const defaultValues = {
    title: '',
    description: '',
    link: '',
  };

  const methods = useForm({
    resolver: yupResolver(VideoSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    const videoID = regex.exec(data.link.trim())[1];
    const toPost = { category: type, title: data.title, description: data.description, link: videoID };

    axios
      .patch(`videos/${id}`, toPost, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        toast.success(`Успешно сохранено! Возвращаемся обратно к списку...`, { autoClose: 2500 });
        setTimeout(() => navigate(`/dashboard/videos/${type}`), 3000);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(`Ошибка: ${err.response?.data.message}`);
      });
  };

  useEffect(() => {
    axios
      .get(`videos/${id}`)
      .then((res) => {
        setValue('title', res.data.title);
        setValue('description', res.data.description);
        setValue('link', `https://youtube.com/watch?v=${res.data.link}`);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(`Ошибка: ${err.response?.data.message}`);
      });
  }, []);

  return (
    <Page title="Редактирование видео">
      <Container maxWidth="lg" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary' }}>
          {type === 'lessons' ? 'Видеоурок' : 'Медитация'}
        </Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField name={'title'} label={'Название видео'} />
            <RHFTextField name={'description'} label={'Описание видео'} multiline rows={4} />
            <RHFTextField name={'link'} label={'Ссылка на видео (YouTube)'} />
            <LoadingButton
              sx={{ m: 1, alignSelf: 'end' }}
              size="medium"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Сохранить
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer />
      </Container>
    </Page>
  );
}
