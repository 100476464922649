import { Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast, ToastContainer } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import { FormProvider, RHFTextField } from '../components/hook-form';
import useToken from '../components/UseToken';
import Page from '../components/Page';
import 'react-toastify/dist/ReactToastify.css';

export default function Magnet() {
  const { token, setToken } = useToken();
  
  const navigate = useNavigate();

  const leadmagnetSchema = Yup.object().shape({
    button: Yup.string(),
    url: Yup.string().url('Это не ссылка'),
  });

  const defaultValues = {
    button: '',
    url: '',
  };

  const methods = useForm({
    resolver: yupResolver(leadmagnetSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    axios
      .put('leadmagnet', data, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        toast.success(`Успешно сохранено! Возвращаемся обратно к списку...`, { autoClose: 2500 });
        setTimeout(() => navigate(`/dashboard/leadmagnet`), 3000);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(`Ошибка: ${err.response?.data.message}`);
      });
  };

  useEffect(() => {    
    axios
      .get(`leadmagnet/`)
      .then((res) => {
        setValue('button', res?.data?.button || '');
        setValue('url', res?.data?.url || '');
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(`Ошибка: ${err.response?.data.message}`);
      });
  }, []);

  return (
    <Page title="Настройка лидмагнита">
      <Container maxWidth="lg" sx={{ ml: 0 }}>
        <Typography variant="h3">Настройка лидмагнита</Typography>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFTextField multiline name={'button'} label={'Текст кнопки'} inputProps={{ maxLength: 94 }} />
            <RHFTextField name={'url'} label={'Ссылка'} />
            <LoadingButton
              sx={{ m: 1, alignSelf: 'end' }}
              size="medium"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Сохранить
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer />
      </Container>
    </Page>
  );
}
