// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'уведомления',
    path: '/dashboard/push',
    icon: getIcon('eva:bell-fill'),
  },
  {
    title: 'история',
    path: '/dashboard/log',
    icon: getIcon('eva:list-fill'),
  },
  {
    title: 'видеоуроки',
    path: '/dashboard/videos/lessons',
    icon: getIcon('eva:film-fill'),
  },
  {
    title: 'медитации',
    path: '/dashboard/videos/meditations',
    icon: getIcon('mdi:meditation'),
  },
  {
    title: 'рекомендации',
    path: '/dashboard/recommendations',
    icon: getIcon('eva:info-fill'),
  },
  {
    title: 'безопасность',
    path: '/dashboard/password',
    icon: getIcon('eva:lock-fill')
  },
  {
    title: 'Настройка лидмагнита',
    path: '/dashboard/leadmagnet',
    icon: getIcon('eva:radio-fill')
  }
];

export default navConfig;
