import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css'
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import useToken from "../../../components/UseToken";
import {FormProvider, RHFTextField} from "../../../components/hook-form";

export default function SchedulePushForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { token, setToken } = useToken();

  const PushSchema = Yup.object().shape({
    name: Yup.string().required('Название обязательно'),
    content: Yup.string().required('Текст обязателен'),
    date: Yup.date().required('Дата обязательна'),
  });

  const defaultValues = {
    name: '',
    content: '',
    date: new Date(),
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/push/id/${id}`, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setValue('name', res.data.name);
          setValue('content', res.data.content);
          setValue('date', res.data.time);
        })
        .catch((err) => {
          if (err.response?.status === 401) setToken('');
          toast.error(`Ошибка при загрузке данных: ${err.response?.data.message}`);
        });
    }
  }, [id, token]);

  const methods = useForm({
    resolver: yupResolver(PushSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    control,
  } = methods;

  const onSubmit = async(data) => {
    setLoading(true);
    axios.post('/push', { category: "manual", name: data.name, content: data.content, time: data.date }, {headers: { Authorization: `Bearer ${token}`}})
      .then(async () => {
        if (id) {
          await axios.delete(`/push/${id}`, { headers: { Authorization: `Bearer ${token}` } });
        }
        toast.success(`Успешно ${id ? 'сохранено' : 'запланировано'}! Возвращаемся обратно к списку...`, {
          autoClose: 2500,
        });
        setTimeout(() => navigate(`/dashboard/push`), 3000);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(err.response?.data.message);
        setLoading(false);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="name" variant="standard" label="Название уведомления" inputProps={{ maxLength: 50 }} />
          <Box
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100wv' }
            }}
            noValidate
            autoComplete="off"
          >
            <RHFTextField name="content" label="Текст уведомления" multiline rows={4} inputProps={{ maxLength: 150 }} />
          </Box>
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DateTimePicker
                label="Дата и время"
                ampm={false}
                disablePast
                inputFormat='DD.MM.YYYY HH:mm'
                onChange={(date) => field.onChange(date)}
                renderInput={(params) => <TextField {...params} />}
                value={field.value}
              />
            )}
          />
          <LoadingButton sx={{m: 1, alignSelf: 'end'}} size="medium" type="submit" variant="contained" loading={loading}>
            {(id) ? 'Сохранить' : 'Запланировать'}
          </LoadingButton>
          <ToastContainer />
        </Stack>
      </FormProvider>
    </LocalizationProvider>
  );
}
