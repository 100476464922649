import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { RecommendationsTable } from '../sections/@dashboard/recommendations';

export default function Recommendations() {
  return (
    <Page title="Рекомендации">
      <Container maxWidth="md" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          Рекомендации для цикла
        </Typography>

        <RecommendationsTable />
      </Container>
    </Page>
  );
}
