import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import useToken from '../components/UseToken';
import 'react-toastify/dist/ReactToastify.css';

export default function Videos() {
  const { type } = useParams();
  const { token, setToken } = useToken();
  const [videos, setVideos] = useState();
  const [rerender, setRerender] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteVideo, setDeleteVideo] = useState('');

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleteVideo(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const toSet = [];

    const deleteClick = (id) => {
      axios
        .delete(`videos/${id}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          toast.success(`Успешно удалено!`);
          setRerender(!rerender);
          handleClose();
        })
        .catch((err) => {
          if (err.response?.status === 401) setToken('');
          toast.error(`Ошибка! Попробуйте ещё раз. Код ошибки: ${err.response?.status}`);
        });
    };

    axios
      .get(`videos/type/${type}`)
      .then((res) => {
        toSet.push(
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Удалить видео?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Вы уверены, что хотите удалить это видео?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ textTransform: 'none' }}>
                Нет, назад
              </Button>
              <Button onClick={() => deleteClick(deleteVideo)} autoFocus>
                Да
              </Button>
            </DialogActions>
          </Dialog>
        );

        res.data.forEach((video) => {
          toSet.push(
            <ListItem
              key={video._id}
              secondaryAction={
                <Box>
                  <IconButton edge="end" aria-label="edit" href={`/dashboard/videos/${type}/edit/${video._id}`}>
                    <Iconify icon="eva-edit-2-outline" />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleClickOpen(video._id)} sx={{ m: 2 }}>
                    <Iconify icon="eva-trash-2-outline" />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText primary={video.title} secondary={video.description} />
            </ListItem>
          );
        });

        setVideos(toSet);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken('');
        toast.error(`Ошибка при загрузке данных! Код ошибки: ${err.response?.status}`);
      });
  }, [open, deleteVideo, rerender, type]);

  return (
    <Page title="Видео">
      <Container maxWidth="lg" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 3 }}>
          {type === 'lessons' ? 'Видеоуроки' : 'Медитации'}
        </Typography>
        <Button
          href={`${type}/add`}
          sx={{ mb: 5, width: '15rem', textTransform: 'none' }}
          variant="contained"
          startIcon={<Iconify icon={'eva-cloud-upload-fill'} />}
        >
          Добавить {type === 'lessons' ? 'видеоурок' : 'медитацию'}
        </Button>

        <Paper style={{ maxHeight: 450, overflow: 'auto' }}>
          <List>
            <Stack divider={<Divider />}>{videos}</Stack>
          </List>
        </Paper>
        {type === 'lessons' ? (
          <Button
            href={`${type}/advert`}
            sx={{ mt: 5, width: '30rem', textTransform: 'none' }}
            variant="contained"
            startIcon={<Iconify icon={'eva-cloud-upload-fill'} />}
          >
            Настроить блок рекламы
          </Button>
        ) : (
          ''
        )}
      </Container>
      <ToastContainer autoClose={1500} />
    </Page>
  );
}
