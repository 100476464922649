// routes
import axios from 'axios';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/Login';
import useToken from './components/UseToken';

// ----------------------------------------------------------------------

export default function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  const { token, setToken } = useToken();

  if (!token || token?.length === 0) return <Login setToken={setToken} />;

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
