import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    return JSON.parse(tokenString);
  };

  const [token, setToken] = useState(getToken());
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('auth/validate', { headers: { Authorization: `Bearer ${token}` } })
      .catch(() => {
        setToken(null);
        navigate('/login');
      });
  }, [token]);

  const saveToken = (userToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
