import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { ChangePasswordForm } from '../sections/auth/password';

export default function ChangePassword() {
  return (
    <Page title="Смена пароля">
      <Container maxWidth="md" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          Смена пароля
        </Typography>

        <ChangePasswordForm />
      </Container>
    </Page>
  );
}
