import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import SchedulePushForm from '../sections/@dashboard/push/SchedulePushForm';

export default function SchedulePush() {
  return (
    <Page title="Уведомления">
      <Container maxWidth="md" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          Планировка уведомлений
        </Typography>

        <SchedulePushForm />
      </Container>
    </Page>
  );
}
