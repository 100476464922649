import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import NotFound from './pages/Page404';
import Push from './pages/Push';
import Recommendations from './pages/Recommendations';
import Videos from './pages/Videos';
import EditRecommendation from './pages/EditRecommendation';
import AddVideo from './pages/AddVideo';
import Advert from './pages/Advert';
import EditVideo from './pages/EditVideo';
import SchedulePush from './pages/SchedulePush';
import Log from './pages/Log';
import Login from './pages/Login';
import useToken from './components/UseToken';
import ChangePassword from './pages/ChangePassword';
import Magnet from './pages/Magnet';

// ----------------------------------------------------------------------
export default function Router() {
  const { setToken } = useToken();

  return (
    <Routes>
      <Route path="/dashboard/*" element={<DashboardLayout />}>
        <Route path="password" element={<ChangePassword />}/>
        <Route path="push" element={<Push />} />
        <Route path="push/schedule" element={<SchedulePush />}>
          <Route path=":id" element={<SchedulePush />} />
        </Route>
        <Route path="log" element={<Log />} />
        <Route path="recommendations/" element={<Recommendations />} />
        <Route path="recommendations/edit/:day" element={<EditRecommendation />} />
        <Route path="videos/:type" element={<Videos />} />
        <Route path="videos/:type/add" element={<AddVideo />} />
        <Route path="videos/:type/edit/:id" element={<EditVideo />} />
        <Route path="videos/:type/advert" element={<Advert />} />
        <Route path="leadmagnet" element={<Magnet />}/>
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="/" element={<LogoOnlyLayout />}>
        <Route path="/login" element={<Login setToken={setToken} />}/>
        <Route path="/" element={<Navigate to="/dashboard/push" />} />
        <Route path="404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
}
