import * as Yup from 'yup';
import {yupResolver} from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useState } from "react";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import useToken from "../../../components/UseToken";

export default function PushForm() {

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pushData, setPushData] = useState();
  const { token, setToken } = useToken();

  const PushSchema = Yup.object().shape({
    name: Yup.string().required('Название обязательно'),
    content: Yup.string().required('Текст обязателен')
  })

  const defaultValues = {
    name: '',
    content: '',
  }

  const methods = useForm({
    resolver: yupResolver(PushSchema),
    defaultValues
  })

  const {
    handleSubmit,
    setValue,
  } = methods;

  const onSubmit = (data) => {
    setOpen(true);
    setPushData(data);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const sendPush = async() => {
    setLoading(true);
    axios.post('push', { category: "manual", name: pushData.name, content: pushData.content }, {headers: { Authorization: `Bearer ${token}`}})
      .then(() => {
        toast.success(`Успешно отправлено!`, { autoClose: 2500 });
        setValue('name', '');
        setValue('content', '');
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) setToken("");
        toast.error(err.response?.data?.message);
        setLoading(false);
      });

    setOpen(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing = {3}>
        <RHFTextField
          name="name"
          variant="standard"
          label="Название уведомления"
          inputProps={{maxLength: 50}}
        />
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '100wv' }
          }}
          noValidate
          autoComplete="off">
          <RHFTextField
            name="content"
            label="Текст уведомления"
            multiline
            rows={4}
            inputProps={{maxLength: 150}}
          />
        </Box>
        <LoadingButton sx={{m: 1, alignSelf: 'end'}} size="medium" type="submit" variant="contained" loading={loading}>
          Отправить
        </LoadingButton>
        <ToastContainer />
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Отправить уведомление?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите отправить это уведомление всем пользователям?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ textTransform: "none" }}>Нет, назад</Button>
          <Button onClick={() => sendPush()} autoFocus>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
