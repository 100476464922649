import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import Iconify from '../../../components/Iconify';
import useToken from '../../../components/UseToken';
import 'react-toastify/dist/ReactToastify.css';

export default function PushTable() {
  const { token, setToken } = useToken();
  const [rerender, setRerender] = useState(false);
  const [open, setOpen] = useState(false);
  const [deletePush, setDeletePush] = useState('');
  const [pushes, setPushes] = useState();

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeletePush(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const toSet = [];

    const deleteClick = (id) => {
      axios
        .delete(`push/${id}`, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          toast.success(`Успешно удалено!`);
          setRerender(!rerender);
          handleClose();
        })
        .catch((err) => {
          if (err.response?.status === 401) setToken('');
          toast.error(`Ошибка! Попробуйте ещё раз. Код ошибки: ${err.response?.status}`);
        });
    };

    axios.get(`push/manual?sort=asc`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        toSet.push(
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Удалить уведомление?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Вы уверены, что хотите удалить это уведомление?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ textTransform: 'none' }}>
                Нет, назад
              </Button>
              <Button onClick={() => deleteClick(deletePush)} autoFocus>
                Да
              </Button>
            </DialogActions>
          </Dialog>
        );

        res.data.result.forEach((push) => {
          if (push.category !== 'manual' || !push.scheduled || new Date() > new Date(push.time)) return;
          toSet.push(
            <ListItem
              key={push._id}
              secondaryAction={
                <Box>
                  <IconButton edge="end" aria-label="edit" href={`/dashboard/push/schedule/${push.onesignal_id}`}>
                    <Iconify icon="eva-edit-2-outline" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleClickOpen(push.onesignal_id)}
                    sx={{ m: 2 }}
                  >
                    <Iconify icon="eva-trash-2-outline" />
                  </IconButton>
                </Box>
              }
            >
            <ListItemText primary={push.name} primaryTypographyProps={{ width: "10rem" }} secondary={moment(push.time).tz("Europe/Moscow").format("DD.MM.YYYY HH:mm z")} />
          </ListItem>)
        })

        setPushes(toSet);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          setToken('');
        }
        toast.error(`Ошибка при загрузке данных! Код ошибки: ${err.response?.status ?? 'неизвестная ошибка'}`);
      });
  }, [deletePush, open, rerender, token]);

  return (
    <Container maxWidth={'lg'} sx={{ mt: 5 }}>
      <Typography variant={'h4'} sx={{ color: 'text.secondary', mb: 3 }}>
        Запланированные уведомления
      </Typography>
      <Button
        href={`/dashboard/push/schedule`}
        sx={{ mb: 5, width: '15rem', textTransform: 'none' }}
        variant="contained"
        startIcon={<Iconify icon={'eva-calendar-fill'} />}
      >
        Запланировать новое
      </Button>
      <Paper style={{ maxHeight: 450, overflow: 'auto' }}>
        <List>
          <Stack divider={<Divider />}>{pushes}</Stack>
        </List>
      </Paper>
    </Container>
  );
}
