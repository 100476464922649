import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { PushForm, PushTable } from '../sections/@dashboard/push';

export default function Push() {
  return (
    <Page title="Уведомления">
      <Container maxWidth="md" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          Отправка уведомлений
        </Typography>

        <PushForm />
        <PushTable />
      </Container>
    </Page>
  );
}
