import { Button, Container, Divider, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';
import useToken from '../components/UseToken';

export default function EditRecommendation() {
  const params = useParams();
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const refs = useRef([]);

  const [notifications, setNotifications] = useState();

  useEffect(() => {
    const handleClick = () => {
      const toSubmit = {
        type: params.day,
        content: [],
      };

      refs.current.forEach((ref, index) => toSubmit.content.push({ name: index, text: ref.value }));

      axios
        .post('notification', toSubmit, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          toast.success(`Успешно сохранено! Возвращаемся обратно к списку...`, { autoClose: 2500 });
          setTimeout(() => navigate('/dashboard/recommendations'), 3000);
        })
        .catch((err) => {
          if (err.response?.status === 401) setToken('');
          toast.error(`Ошибка! Попробуйте ещё раз. Код ошибки: ${err.response?.status}`);
        });
    };

    axios
      .get(`notification/${params.day}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((notifs) => {
        const array = [];
        Object.entries(notifs.data).forEach((value) => {
          array.push(
            <Typography variant="h5" align="center">
              День {value[0]}
            </Typography>
          );
          array.push(
            <TextField
              inputRef={(el) => {
                refs.current[value[0]] = el;
              }}
              fullWidth
              id={value[0].toString()}
              label="Рекомендация"
              multiline
              margin="normal"
              defaultValue={value[1]}
            />
          );
          array.push(<Divider />);
        });
        array.push(
          <Button
            sx={{ align: 'center', mt: 10 }}
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            onClick={handleClick}
          >
            Сохранить
          </Button>
        );
        setNotifications(array);
      })
      .catch((err) => {
        if (err.response?.status === 404)
          setNotifications('Не найдено! Вы уверены, что находитесь на нужной странице?');
        else if (err.response?.status === 401) setToken('');
        else setNotifications(err.response?.data.message);
      });
  }, [token]);

  return (
    <Page title="Настройка рекомендаций">
      <Container maxWidth="lg">
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          Настройка рекомендаций, {params.day}-дневный цикл
        </Typography>

        {notifications}
        <ToastContainer />
      </Container>
    </Page>
  );
}
