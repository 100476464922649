import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import parseJwt from '../../../util/parseJwt';
import useToken from '../../../components/UseToken';

export default function ChangePasswordForm() {
  const {token, setToken} = useToken();
  const navigate = useNavigate();

  const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Старый пароль обязателен'),
    newPassword: Yup.string().required('Новый пароль обязателен'),
    confirmPassword: Yup.string().required('Подтверждение пароля обязательно'),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const methods = useForm({
    resolver: yupResolver(PasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    if (data.newPassword !== data.confirmPassword) return setError('confirmPassword', { message: 'Пароль не подтверждён!' });
    if (data.newPassword === data.oldPassword) return setError('newPassword', { message: 'Новый пароль не может совпадать со старым!' });
    const parsedToken = parseJwt(token);
    setValue(isSubmitting, true);
    return axios.post('auth/login', { username: parsedToken.username, password: data.oldPassword })
      .then(() =>
      axios.patch('admin', { username: parsedToken.username, password: data.newPassword }, {headers: {Authorization: `Bearer ${token}`}}))
      .then(() => {
        toast.success("Пароль успешно изменён! Вам придётся перезайти в аккаунт.");
        setTimeout(() => navigate('/login'), 3000);
      })
      .catch((err) => {
      if (err.response?.status === 401) return setError('oldPassword', { message: 'Неправильный пароль!' });
      setError('oldPassword', { message: 'Возникла непредвиденная ошибка! Попробуйте ещё раз.' });
      return setValue(isSubmitting, false);
    })
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="oldPassword" label="Старый пароль" type="password" />
        <RHFTextField name="newPassword" label="Новый пароль" type='password' />
        <RHFTextField name="confirmPassword" label="Подтверждение пароля" type="password"/>
      </Stack>
      <LoadingButton size="large" type="submit" variant="contained" sx={{mt: 5, textTransform: 'none'}} loading={isSubmitting}>
        Сменить пароль
      </LoadingButton>
      <ToastContainer />
    </FormProvider>
  )
}
