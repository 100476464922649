import { IconButton, List, ListItem, ListItemText } from "@mui/material";
import { cloneElement } from "react";
import Iconify from "../../../components/Iconify";

function generate(element) {
  const array = [];
  for (let i = 21; i <= 35; i+=1) array.push(i);
  // noinspection JSCheckFunctionSignatures
  return array.map((value) =>
  cloneElement(element, { key: value,
    secondaryAction:
  <IconButton edge="end" aria-label="edit" href={`/dashboard/recommendations/edit/${value}`}>
    <Iconify icon="eva-edit-2-outline" />
  </IconButton>
  },
    <ListItemText
    id="text"
    primary={`${value}-дневный цикл`}/>));
}

export default function RecommendationsTable() {
  return (
  <List>
    {generate(
      <ListItem />)}
  </List>
  )
}
