import { Container, Typography } from '@mui/material';
import Page from '../components/Page';
import PushLogTable from '../sections/@dashboard/log/PushLogTable';

export default function Log() {
  return (
    <Page title="История">
      <Container maxWidth="xl" sx={{ ml: 0 }}>
        <Typography variant="h3" sx={{ color: 'text.secondary', mb: 5 }}>
          История уведомлений
        </Typography>

        <PushLogTable />
      </Container>
    </Page>
  );
}
