import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Page title="404">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Извините, страница не найдена!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Мы не смогли найти страницу, которую вы запросили. Возможно, ошибка в URL? Попробуйте вернуться на главную и
            перейти на страницу заново.
          </Typography>

          <Box component="img" src="/static/404.svg" sx={{ height: 400, mx: 'auto' }} />

          <Button to="/" size="large" variant="contained" sx={{ textTransform: 'none' }} component={RouterLink}>
            Вернуться на главную
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
